<template lang="pug">
  section(data-mm-component-name="Mansions_Search")
    h2
      | 中古マンションを探す

    Discover

    nav.recommended_in_metropolitan
      h3
        | 人気のエリア
      ul
        li(v-for="city in mansionsSearch.popularCities")
          a.lazyload(v-bind:href="city.link" v-bind:data-bg="city.image_url")
            | {{city.name}}
      p
        template(v-for="city in mansionsSearch.tokyoCities")
          a(v-bind:href="city.link")
            | {{city.name}}
          | ｜
        br
        template(v-for="city in mansionsSearch.kanagawaCities")
          a(v-bind:href="city.link")
            | {{city.name}}
          | ｜

    nav.recommended_stations
      h3
        | 人気の駅
      ul
        li(v-for="station in mansionsSearch.popularStations")
          a.lazyload(v-bind:href="station.link" v-bind:data-bg="station.image_url")
            | {{station.name}}

    nav.search_by_pref
      h3
        | 都道府県から探す
      ul
        li(v-for="prefecture in mansionsSearch.popularPrefectures")
          a.lazyload(v-bind:href="prefecture.link" v-bind:data-bg="prefecture.image_url")
            | {{prefecture.name}}
      dl(v-if="otherArea == 'open'")
        template(v-for="area in mansionsSearch.groupedPrefectures")
          dt
            | {{area.area_name}}
          dd
            template(v-for="prefecture in area.prefectures")
              a(v-bind:href="prefecture.link")
                | {{prefecture.name}}
              | ｜
      p.more(v-else)
        a(href="#mansions" v-on:click.stop.prevent="openOtherArea")
          | 他の都道府県も見る
          i.fa.fa-chevron-down(aria-hidden="true")

    Ranking

    nav.popular_mansions
      h3
        | おすすめマンション
      ul
        li(v-for="mansion in mansionsSearch.popularBuildings")
          a(v-bind:href="mansion.link")
            img.lazyload(v-bind:data-src="mansion.image_url" v-bind:alt="mansion.name")
            span
              | {{mansion.name}}
      p.banner
        a(href="/mansions/categories/brands")
          img.lazyload(v-bind:data-src="require('../../../../images/v2019/top/bnr_brand.jpg')" alt="ブランドマンション特集")
        a(href="/tower-mansion")
          img.lazyload(v-bind:data-src="require('../../../../images/v2019/top/bnr_tower.jpg')" alt="タワーマンション特集")
</template>

<script>
  import Discover from 'vue_apps/top/components/pc/Discover';
  import Ranking from 'vue_apps/top/components/pc/Ranking';

  export default {
    name: 'Mansions_Search',
    methods: {
      openOtherArea() {
        this.otherArea = 'open';
        return;
      }
    },
    data() {
      return {
        mansionsSearch: this.$store.state.mansionsSearch,
        otherArea: '',
      }
    },
    components: {
      Discover,
      Ranking
    }
  };
</script>

<style scoped lang="scss">
  @import 'app/assets/stylesheets/v2019/color.scss';

  section {
    width: 1024px;
    margin: 0 auto;
    padding: 55px 0 120px 0;

    a {
      color: $mm_exblue;
    }

    h2 {
      margin: 60px auto 0 auto;
      padding: 0;
      box-sizing: border-box;
      letter-spacing: 1.3;
      font-size: 36px;
      color: $mm_black;
    }

    h3 {
      margin: 40px 0 25px 0;
      padding: 0;
      font-size: 28px;
      line-height: 1.8;
    }

    p {
      margin: 30px 0;
      padding: 0;
      line-height: 1.8;

      &.more {
        margin: 15px 0 0 0;
        text-align: center;
        font-size: 22px;
        font-weight: bold;

        a {
          position: relative;
          display: inline-block;
          padding: 0;
          width: 600px;
          height: 75px;
          line-height: 74px;
          text-decoration: none;
          border: solid 1px $mm_blue;
          border-radius: 5px;
          color: $mm_blue;
          background: $mm_white;

          &:link,
          &:visited {
            border-width: 2px;
          }

          &:hover,
          &:focus {
            background: $mm_blue;
            color: $mm_white;

            i.fa {
              color: $mm_white;
            }
          }

          i.fa {
            position: absolute;
            top: 50%;
            right: 30px;
            margin-top: -11px;
            font-size: 20px;
            color: $mm_blue;
          }
        }
      }
    }

    nav {

      &.recommended_in_metropolitan {

        ul {
          display: flex;
          flex-flow: row wrap;
          justify-content: left;
          margin: 25px 0 20px 0;
          padding: 0;
          list-style: none;

          li {
            display: inline-block;
            margin: 0 20px 20px 0;
            padding: 0;
            width: 240px;
            height: 145px;
            overflow: hidden;
            text-align: center;

            &:nth-child(4n+4),
            &:last-child {
              margin: 0 0 20px 0;
            }

            a {
              position: relative;
              display: block;
              width: 100%;
              height: 145px;
              line-height: 145px;
              font-size: 36px;
              font-weight: bold;
              text-decoration: none;
              color: $mm_white;
              background: $mm_gray no-repeat center top;
              background-size: cover;
              transition: all 0.3s ease-out;
              transform: matrix3d(
                1, 0, 0, 0,
                0, 1, 0, 0,
                0, 0, 1, 0,
                0, 0, 0, 1
              );
              will-change: transform;
              opacity: 0.88;

              &:hover,
              &:focus {
                transform: matrix3d(
                  1, 0, 0, 0,
                  0, 1, 0, 0,
                  0, 0, 1, 0,
                  0, 0, 0, 0.88
                );
                opacity: 1;
              }
            }
          }
        }

        p {
          margin: 20px 0;
        }
      }

      &.recommended_stations {

        h3 {
          margin: 40px 0 5px 0;
        }

        ul {
          display: flex;
          flex-flow: row wrap;
          justify-content: left;
          margin: 0 0 20px 0;
          padding: 0;
          list-style: none;

          li {
            display: inline-block;
            width: 154px;
            height: 145px;
            margin: 20px 20px 0 0;
            padding: 0;
            overflow: hidden;
            text-align: center;

            &:nth-child(6n+6),
            &:last-child {
              margin: 20px 0 0 0;
            }

            a {
              display: block;
              width: 154px;
              height: 145px;
              line-height: 145px;
              font-size: 28px;
              font-weight: bold;
              text-decoration: none;
              color: $mm_white;
              background: $mm_gray no-repeat center top;
              background-size: cover;
              transition: all 0.3s ease-out;
              transform: matrix3d(
                1, 0, 0, 0,
                0, 1, 0, 0,
                0, 0, 1, 0,
                0, 0, 0, 1
              );
              will-change: transform;
              opacity: 0.88;

              &:hover,
              &:focus {
                transform: matrix3d(
                  1, 0, 0, 0,
                  0, 1, 0, 0,
                  0, 0, 1, 0,
                  0, 0, 0, 0.88
                );
                opacity: 1;
              }
            }
          }
        }
      }

      &.search_by_pref {

        ul {
          display: flex;
          flex-flow: row wrap;
          justify-content: left;
          margin: 25px 0 10px 0;
          padding: 0;
          list-style: none;

          li {
            display: inline-block;
            width: 240px;
            height: 145px;
            margin: 0 20px 20px 0;
            padding: 0;
            overflow: hidden;
            text-align: center;

            &:nth-child(4n+4),
            &:last-child {
              margin: 0 0 20px 0;
            }

            a {
              display: block;
              width: 100%;
              height: 145px;
              line-height: 145px;
              font-size: 36px;
              font-weight: bold;
              text-decoration: none;
              color: $mm_white;
              background: $mm_gray no-repeat center top;
              background-size: cover;
              transition: all 0.3s ease-out;
              transform: matrix3d(
                1, 0, 0, 0,
                0, 1, 0, 0,
                0, 0, 1, 0,
                0, 0, 0, 1
              );
              will-change: transform;
              opacity: 0.88;

              &:hover,
              &:focus {
                transform: matrix3d(
                  1, 0, 0, 0,
                  0, 1, 0, 0,
                  0, 0, 1, 0,
                  0, 0, 0, 0.88
                );
                opacity: 1;
              }
            }
          }
        }

        dl {
          display: flex;
          flex-wrap: wrap;
          margin: 0 0 40px 0;
          padding: 0;
          line-height: 2;

          dt {
            display: block;
            width: 13%;
            margin: 0;
            padding: 0;
            font-weight: bold;
            letter-spacing: 1px;
          }

          dd {
            display: block;
            width: 87%;
            margin: 0;
            padding: 0;
          }
        }
      }

      &.popular_mansions {

        ul {
          display: flex;
          flex-flow: row wrap;
          justify-content: space-between;
          margin: 25px 0;
          padding: 0;
          list-style: none;

          li {
            display: inline-block;
            margin: 0 20px 20px 0;
            padding: 0;
            text-align: left;

            &:nth-child(5n+5),
            &:last-child {
              margin: 0 0 20px 0;
            }

            a {
              position: relative;
              display: block;
              width: 168px;
              height: 235px;
              overflow: hidden;
              font-size: 16px;
              text-decoration: none;
              color: $mm_blue;

              &:hover:after,
              &:focus:after {
                position: absolute;
                content: '';
                display: block;
                z-index: 10;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background: rgba($mm_white, 0.25);
              }

              img {
                margin: 0 0 6px 0;
                width: auto;
                height: 168px;
                vertical-align: middle;
                transform: scale(1);
                transition: .3s ease-in-out;
              }

              &:hover img,
              &:focus img {
                transform: scale(1.2);
              }

              span {
                position: absolute;
                display: block;
                z-index: 100;
                width: 100%;
                height: 65px;
                left: 0;
                right: 0;
                bottom: 0;
                line-height: 1.4;
                background: $mm_white;
                border-top: solid 10px $mm_white;
              }

              &:hover span,
              &:focus span {
                text-decoration: underline;
              }
            }
          }
        }

        p.banner {
          margin: 40px 0 0 0;
          text-align: center;

          a:first-child img {
            margin: 0 84px 0 0;
          }

          img {
            vertical-align: middle;
          }
        }
      }
    }
  }
</style>

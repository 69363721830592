<template lang="pug">
  nav.choice_our_services(v-bind:class="position" data-mm-component-name="ChoiceOurServices")
    ul
      li
        a(href="/satei")
          | マンション売却・査定依頼
      li
        a.buy(href="/mansions")
          | 中古マンション購入・物件検索
      li
        a.sell(href="/sell")
          | 不動産売却・査定依頼
</template>

<script>
  export default {
    name: 'ChoiceOurServices',
    props: [ 'position' ],
  };
</script>

<style scoped lang="scss">
  @import 'app/assets/stylesheets/v2019/color.scss';

  nav.choice_our_services {
    margin: 0;
    padding: 33px 7px;
    background: $mm_darkgray url('../../../../images/v2019/top/top_cta_wrapper.jpg') no-repeat center center;
    background-size: cover;

    &.in_header {
      position: absolute;
      z-index: 15;
      top: 200px;
      left: 0;
      margin: 0;
      padding: 0 10px;
      width: 100%;
      box-sizing: border-box;
      background: transparent;
    }

    ul {
      list-style: none;
      max-width: 400px;
      margin: 0 auto;
      padding: 5px 5px 8px 5px;
      box-sizing: border-box;
      border-radius: 3px;
      box-shadow: 0px 3px 6px #00000029;
      background: $mm_white;

      li {
        margin: 11px 10px;
        padding: 0;
        text-align: center;

        a {
          display: block;
          position: relative;
          height: 50px;
          line-height: 50px;
          margin: 0 auto 0 auto;
          padding: 0;
          font-size: 16px;
          font-weight: bold;
          box-sizing: border-box;
          box-shadow: 0 3px 0 0 #007d99;
          border-radius: 3px;
          text-decoration: none;
          background: $mm_blue;
          color: $mm_white;

          &:hover,
          &:focus {
            top: 3px;
            opacity: 0.66;
            box-shadow: none;
          }

          &.buy {
            background: $mm_green;
            box-shadow: 0px 3px 0px #067235;

            &:hover,
            &:focus {
              box-shadow: none;
            }
          }

          &.sell {
            background: $mm_white;
            color: $mm_blue;
            box-shadow: 0px 3px 0px $mm_blue;
            border: solid 1px $mm_blue;

            &:hover,
            &:focus {
              box-shadow: none;
            }
          }

          i.fa {
            margin: 0 10px 0 0;
          }
        }
      }
    }
  }
</style>

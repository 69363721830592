<template lang="pug">
  .discover(data-mm-component-name="Discover")
    section
      .main_areas_map
        h2 エリア／沿線・駅から探す
        ul.map
          li(v-for="prefecture in mapPrefectures" v-bind:class="prefecture.name_romaji")
            em {{prefecture.name}}
            a(v-bind:href="prefecture.area_path")
              | エリア
            a(v-on:click="showAreaModal(prefecture)")
              | 沿線・駅
        AreaModal(v-if="show_area_modal")

      .main_areas_list
        h2 主要エリアから探す
        dl
          template(v-for="prefecture in mainAreaPrefectures")
            dt {{prefecture.name}}
            dd
              ul
                li(v-for="city in prefecture.cities")
                  a(v-bind:href="city.path")
                    | {{city.name}}
                    small
                      | （{{city.count}}件）
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import AreaModal from 'vue_apps/top/components/pc/AreaModal';

  export default {
    name: 'Discover',
    data() {
      return {
        mainAreaPrefectures: this.$store.state.mainAreaPrefectures,
        mapPrefectures: this.$store.state.mapPrefectures,
      }
    },
    methods: mapMutations(['showAreaModal']),
    computed: {
      ...mapState({
        show_area_modal: state => state.show_area_modal,
      })
    },
    components: {
      AreaModal,
    },
  };
</script>

<style scoped lang="scss">
  @import 'app/assets/stylesheets/v2019/color.scss';

  .discover {
    padding: 1px 0;

    section {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 30px auto 0 auto;
      width: 1024px;

      a {
        color: $mm_exblue;
      }

      h2 {
        margin: 0;
        padding: 0;
        font-size: 28px;
      }

      .main_areas_map {
        margin: 0 0 30px 0;
        width: 580px;

        ul.map {
          position: relative;
          margin: 15px 0 0 0;
          padding: 0;
          list-style: none;
          height: 425px;
          overflow: hidden;
          background: url('../../../../images/v2019/top/baikyaku_area_bg.png') no-repeat center bottom;
          background-size: cover;

          li {
            position: absolute;
            margin: 0;
            padding: 10px;
            width: 213px;
            box-sizing: border-box;
            text-align: center;
            font-size: 18px;
            border: solid 2px $mm_green;
            border-radius: 5px;
            background: $mm_white;

            &.saitama {
              top: 40px;
              left: 105px;
            }

            &.tokyo {
              top: 167px;
              left: 87px;
            }

            &.kanagawa {
              top: 293px;
              left: 40px;
            }

            &.chiba {
              top: 204px;
              left: 328px;
            }

            em {
              display: block;
              margin: 0 0 6px 0;
              padding: 0 0 6px 0;
              font-style: normal;
              font-weight: bold;
              border-bottom: dotted 1px $mm_gray;
            }

            a {
              display: inline-block;
              margin: 0 10px;
              cursor: pointer;
            }
          }
        }
      }

      .main_areas_list {
        margin: 0 0 30px 0;
        width: 420px;

        dl {
          display: flex;
          flex-wrap: wrap;
          margin: 15px 0 0 0;
          padding: 0;
          list-style: none;
          height: 425px;
          overflow: hidden;
          box-sizing: border-box;
          border: solid 2px $mm_truegreen;
          border-radius: 5px;
          background: $mm_white;

          dt {
            display: block;
            width: 100px;
            margin: 0;
            padding: 7px 0 0 24px;
            box-sizing: border-box;
            font-size: 18px;
            font-weight: bold;
          }

          dd {
            width: 314px;
            margin: 0;
            padding: 7px 0 0 10px;
            box-sizing: border-box;
            line-height: 1.7;

            ul {
              display: flex;
              flex-wrap: wrap;
              margin: 0;
              padding: 0;
              list-style: none;

              li {
                width: 50%;
                display: inline-block;
                margin: 0;
                padding: 0;

                small {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
  }
</style>

<template lang="pug">
  .discover(data-mm-component-name="Discover")
    section
      .area_selection
        h2(v-on:click="toggle_switch('area_search')")
          | エリアから探す
          i.fa.fa-chevron-circle-down(aria-hidden="true")
        ul.link_list(v-show="toggle.area_search")
          li(v-for="prefecture in mapPrefectures")
            a(v-bind:href="prefecture.area_path")
              | {{prefecture.name}}
              i.fa.fa-chevron-right(aria-hidden="true")

      .area_selection
        h2(v-on:click="toggle_switch('railway_search')")
          | 沿線・駅から探す
          i.fa.fa-chevron-circle-down(aria-hidden="true")
        ul.link_list(v-show="toggle.railway_search")
          li(v-for="prefecture in mapPrefectures")
            a(v-on:click="showAreaModal(prefecture)")
              | {{prefecture.name}}
              i.fa.fa-chevron-right(aria-hidden="true")
        AreaModal(v-if="show_area_modal")

      .area_selection
        h2(v-on:click="toggle_switch('main_area')")
          | 主要エリアから探す
          i.fa.fa-chevron-circle-down(aria-hidden="true")
        dl(v-show="toggle.main_area")
          template(v-for="prefecture in mainAreaPrefectures")
            dt
              | {{prefecture.name}}
            dd
              ul
                li(v-for="city in prefecture.cities")
                  a(v-bind:href="city.path")
                    | {{city.name}}
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import AreaModal from 'vue_apps/top/components/sp/AreaModal';

  export default {
    name: 'Discover',
    data() {
      return {
        mainAreaPrefectures: this.$store.state.mainAreaPrefectures,
        mapPrefectures: this.$store.state.mapPrefectures,
        toggle: {
          area_search: true,
          railway_search: false,
          main_area: false,
        }
      }
    },
    methods: {
      toggle_switch: function( target ){
        this.toggle[target] = !this.toggle[target];
      },
      ...mapMutations(['showAreaModal']),
    },
    computed: {
      ...mapState({
        show_area_modal: state => state.show_area_modal,
      })
    },
    components: {
      AreaModal,
    },
  };
</script>

<style scoped lang="scss">
  @import 'app/assets/stylesheets/v2019/color.scss';

  .discover {

    section {
      margin: 11px auto 0 auto;
      padding: 0 0 1px 0;

      a {
        color: $mm_exblue;
      }

      h2 {
        margin: 0;
        padding: 0;
        font-size: 16px;
        color: $mm_black;
      }

      .area_selection {
        margin: 11px 20px;
        border: solid 2px $mm_green;
        border-radius: 5px;
        overflow: hidden;

        h2 {
          position: relative;
          padding: 11px 0;
          background: $mm_bggreen;
          text-align: center;

          i.fa {
            position: absolute;
            top: 50%;
            right: 11px;
            margin-top: -8px;
            color: $mm_green;
          }
        }

        ul.link_list {
          margin: 0;
          padding: 0;
          list-style: none;

          li {
            margin: 0;
            padding: 0;
            border-bottom: solid 1px $mm_lightgray;

            &:last-child {
              border-bottom: none;
            }

            a {
              position: relative;
              display: block;
              padding: 11px;
              box-sizing: border-box;
              text-decoration: none;
              font-weight: bold;
              font-size: 16px;
              cursor: pointer;
              color: $mm_black;

              i.fa {
                position: absolute;
                top: 50%;
                right: 11px;
                margin-top: -8px;
                color: $mm_disablegray;
              }
            }
          }
        }

        dl {
          margin: 0;
          padding: 0;
          list-style: none;

          dt {
            position: relative;
            display: block;
            padding: 11px;
            box-sizing: border-box;
            text-decoration: none;
            font-weight: bold;
            font-size: 16px;
            color: $mm_black;
            border-top: solid 1px $mm_lightgray;

            &:first-child {
              border-bottom: none;
            }

            i.fa {
              position: absolute;
              top: 50%;
              right: 11px;
              margin-top: -8px;
              color: $mm_disablegray;
            }
          }

          dd {
            margin: 0;
            padding: 0 11px 11px 11px;

            ul {
              margin: 0;
              padding: 0;
              list-style: none;

              li {
                display: inline-block;
                margin: 0 11px 7px 0;
                padding: 0;
              }
            }
          }
        }
      }
    }
  }
</style>

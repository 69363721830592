<template lang="pug">
  .wrapper(data-mm-layout-name="PcGrandTopPage")

    // お知らせ用
    //Information

    .grandtop_header
      h1
        | マンションのことなら マンションマーケット
      h2
        | マンションの購入＆売却をサポート
      p.about_us
        | マンションごとの相場価格や取引価格、不動産エージェントの実績やレビューなど、マンションを購入＆売却する時に役立つ情報をまとめて掲載しています。

      ChoiceOurServices(position="in_header")

    Mansions_Search

    Magazine

    ChoiceOurServices

    Services
    Media
</template>

<script>
  // import Information from 'vue_apps/top/components/pc/Information';
  import ChoiceOurServices from 'vue_apps/top/components/pc/ChoiceOurServices';
  import Mansions_Search from 'vue_apps/top/components/pc/Mansions_Search';
  import Magazine from 'vue_apps/top/components/pc/Magazine';
  import Services from 'vue_apps/top/components/pc/Services';
  import Media from 'vue_apps/top/components/pc/Media';

  export default {
    name: 'PcGrandTopPage',
    components: {
      // Information,
      ChoiceOurServices,
      Mansions_Search,
      Magazine,
      Services,
      Media,
    },
  };
</script>

<style scoped lang="scss">
  @import 'app/assets/stylesheets/v2019/color.scss';

  .wrapper {
    background: $mm_white;
  }

  .grandtop_header {
    position: relative;
    padding: 1px 0 0 0;
    height: 525px;
    text-align: left;
    background: no-repeat url('../../../../images/v2019/top/mainimage_pc.jpg') center center;
    background-size: auto;

    h1 {
      display: none;
    }

    h2 {
      margin: 128px auto 30px auto;
      padding: 0 344px 0 0;
      width: 1024px;
      box-sizing: border-box;
      font-size: 42px;
      font-weight: bold;
      color: $mm_black;
    }

    p.about_us {
      margin: 30px auto;
      padding: 0 330px 0 0;
      width: 1024px;
      box-sizing: border-box;
      line-height: 1.8;
      font-size: 18px;
    }
  }
</style>

<template lang="pug">
  .services_wrapper(data-mm-component-name="Services")

    section
      h2
        | サービス紹介

      .left_services_menu
        nav.services_menu.satei
          h3
            | マンションを売る
          ul
            li(v-for="satei in service.sateiServices")
              a(v-bind:href="satei.link")
                | {{satei.name}}
                i.fa.fa-chevron-right(aria-hidden="true")

      .right_services_menu
        nav.services_menu.purchase
          h3
            | マンションを買う
          ul
            li(v-for="purchase in service.purchaseServices")
              a(v-bind:href="purchase.link")
                | {{purchase.name}}
                i.fa.fa-chevron-right(aria-hidden="true")

        nav.services_menu.how_to_mansion
          h3
            | マンションについて知る
          ul
            li(v-for="howTo in service.howToMansion")
              a(v-bind:href="howTo.link")
                | {{howTo.name}}
                i.fa.fa-chevron-right(aria-hidden="true")

      h3
        | マンションを探す
      h4
        | 東京都
      p
        template(v-for="city in service.searchArea.tokyo")
          a(v-bind:href="city.link")
            | {{city.name}}
          | ｜
      h4
        | 神奈川県
      p
        template(v-for="city in service.searchArea.kanagawa")
          a(v-bind:href="city.link")
            | {{city.name}}
          | ｜
      h4
        | 千葉県
      p
        template(v-for="city in service.searchArea.chiba")
          a(v-bind:href="city.link")
            | {{city.name}}
          | ｜
      h4
        | 埼玉県
      p
        template(v-for="city in service.searchArea.saitama")
          a(v-bind:href="city.link")
            | {{city.name}}
          | ｜
</template>

<script>
  export default {
    name: 'Services',
    data() {
      return {
        service: this.$store.state.service,
      }
    }
  };
</script>

<style scoped lang="scss">
  @import 'app/assets/stylesheets/v2019/color.scss';

  .services_wrapper {
    padding: 100px 0;
    background: $mm_white;

    section {
      width: 1024px;
      margin: 0 auto;

      h2 {
        margin: 0 auto;
        padding: 0;
        box-sizing: border-box;
        letter-spacing: 1.3;
        font-size: 36px;
        color: $mm_black;

        span.for {
          display: block;
          margin: 0 0 20px 0;
          font-size: 24px;
          color: $mm_exblue;

          img {
            margin: 0 20px 0 0;
            vertical-align: text-bottom;
          }
        }
      }

      h3 {
        clear: both;
        margin: 20px 0;
        padding: 0;
        font-size: 28px;
      }

      h4 {
        margin: 20px 0;
        padding: 0;
        font-size: 16px;
      }

      p {
        margin: 20px 0;
        padding: 0;
        line-height: 1.8;
      }

      a {
        color: $mm_exblue;
      }

      .left_services_menu {
        float: left;
        width: 485px;
        padding: 0 0 40px 0;
      }

      .right_services_menu {
        float: right;
        width: 485px;
        padding: 0 0 40px 0;
      }

      .services_menu {

        &.purchase ul li a:after {
          background: $mm_bggreen;
        }

        &.how_to_mansion ul li a:after {
          background: $mm_bgorange;
        }

        ul {
          margin: 0;
          padding: 0;
          list-style: none;
          overflow: hidden;
          border: solid 1px $mm_disablegray;
          border-radius: 7px;

          li {
            margin: 0;
            padding: 0;

            &:last-child a {
              border-bottom: none;
            }

            a {
              position: relative;
              display: block;
              padding: 0 0 0 30px;
              height: 50px;
              line-height: 50px;
              box-sizing: border-box;
              font-size: 18px;
              text-decoration: none;
              border-bottom: solid 1px $mm_disablegray;

              &:hover,
              &:focus {
                background: $mm_ultralightgray;
              }

              &:after {
                position: absolute;
                z-index: 100;
                content: '';
                top: 0;
                bottom: 0;
                right: 0;
                width: 65px;
                height: 100%;
                background: $mm_whiteblue;
              }

              i {
                position: absolute;
                z-index: 1000;
                top: 50%;
                right: 25px;
                margin: -9px 0 0 0;
                font-weight: normal;
                color: $mm_gray;
              }
            }
          }
        }
      }
    }
  }
</style>

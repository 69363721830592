<template lang="pug">
  .wrapper(data-mm-layout-name="SpGrandTopPage")

    // お知らせ用
    //Information

    .grandtop_header
      h1
        | マンションのことなら マンションマーケット
      h2
        | マンションの購入
        br
        | ＆売却をサポート
      p.about_us
        | マンションごとの相場価格や取引価格、
        br
        | 不動産エージェントの実績やレビューなど、
        br
        | マンションを購入＆売却する時に
        br
        | 役立つ情報をまとめて掲載しています。

      ChoiceOurServices(position="in_header")

    Mansions_Search

    Magazine

    ChoiceOurServices

    Services
    Media
</template>

<script>
  //import Information from 'vue_apps/top/components/sp/Information';
  import ChoiceOurServices from 'vue_apps/top/components/sp/ChoiceOurServices';
  import Mansions_Search from 'vue_apps/top/components/sp/Mansions_Search';
  import Magazine from 'vue_apps/top/components/sp/Magazine';
  import Services from 'vue_apps/top/components/sp/Services';
  import Media from 'vue_apps/top/components/sp/Media';

  export default {
    name: 'SpGrandTopPage',
    components: {
      //Information,
      ChoiceOurServices,
      Mansions_Search,
      Magazine,
      Services,
      Media,
    },
  };
</script>

<style scoped lang="scss">
  @import 'app/assets/stylesheets/v2019/color.scss';

  .wrapper {
    background: $mm_white;
  }

  .grandtop_header {
    position: relative;
    margin: 0 auto;
    padding: 1px 0;
    max-width: 400px;
    height: 230px;
    text-align: left;
    background: no-repeat url('../../../../images/v2019/top/mainimage_sp2.jpg') center center;
    background-size: auto 242px;

    h1 {
      display: none;
    }

    h2 {
      margin: 20px 0 10px 22px;
      padding: 0;
      width: 160px;
      font-size: 20px;
      font-weight: bold;
    }

    p.about_us {
      margin: 10px 0 10px 22px;
      padding: 0;
      font-size: 10px;
      text-shadow: 1px 0 7px $mm_white;
    }
  }
</style>

<template lang="pug">
  .magazine_wrapper(data-mm-component-name="Magazine")
    section.magazine
      h2
        | マンションマガジン記事特集

      h3
        | 人気記事
      ul
        li(v-for="content in popularContents")
          a(v-bind:href="content.href" target="_blank")
            img.lazyload(v-bind:data-src="content.img_src" v-bind:alt="'【マンションマーケットマガジン】' + content.title")
            span
              | {{content.title}}

      h3
        | 新着記事
      ul
        li(v-for="content in latestContents")
          a(v-bind:href="content.href" target="_blank")
            img.lazyload(v-bind:data-src="content.img_src" v-bind:alt="'【マンションマーケットマガジン】' + content.title")
            span
              | {{content.title}}

      p.more
        a(href="https://mansion-market.com/contents/" target="_blank")
          i.fa.fa-angle-right(aria-hidden="true")
          | 記事一覧へ
</template>

<script>
  export default {
    name: 'Media',
    data() {
      return {
        popularContents: [
          {
            title: '東京都のマンション売却相場は？いま売却して得する高評価マンションを独自分析',
            href: 'https://mansion-market.com/contents/detail/1',
            img_src: require('../../../../images/top/magazine/ranking_1.png'),
          },
          {
            title: '千代田区のマンション売却相場は？いま売却して得する高評価マンションを独自分析',
            href: 'https://mansion-market.com/contents/detail/2',
            img_src: require('../../../../images/top/magazine/ranking_2.png'),
          },
          {
            title: '中央区のマンション売却相場は？いま売却して得する高評価マンションを独自分析',
            href: 'https://mansion-market.com/contents/detail/3',
            img_src: require('../../../../images/top/magazine/ranking_3.png'),
          },
          {
            title: '港区のマンション売却相場は？いま売却して得する高評価マンションを独自分析',
            href: 'https://mansion-market.com/contents/detail/4',
            img_src: require('../../../../images/top/magazine/ranking_4.png'),
          },
          {
            title: '新宿区のマンション売却相場は？いま売却して得する高評価マンションを独自分析',
            href: 'https://mansion-market.com/contents/detail/5',
            img_src: require('../../../../images/top/magazine/ranking_5.png'),
          },
          {
            title: '渋谷区のマンション売却相場は？いま売却して得する高評価マンションを独自分析',
            href: 'https://mansion-market.com/contents/detail/14',
            img_src: require('../../../../images/top/magazine/ranking_14.png'),
          },
        ],
        latestContents: [
          {
            title: '世田谷区のマンション売却相場は？いま売却して得する高評価マンションを独自分析',
            href: 'https://mansion-market.com/contents/detail/13',
            img_src: require('../../../../images/top/magazine/ranking_13.png'),
          },
          {
            title: '目黒区のマンション売却相場は？いま売却して得する高評価マンションを独自分析',
            href: 'https://mansion-market.com/contents/detail/11',
            img_src: require('../../../../images/top/magazine/ranking_11.png'),
          },
          {
            title: '品川区のマンション売却相場は？いま売却して得する高評価マンションを独自分析',
            href: 'https://mansion-market.com/contents/detail/10',
            img_src: require('../../../../images/top/magazine/ranking_10.png'),
          },
          {
            title: '中野区のマンション売却相場は？いま売却して得する高評価マンションを独自分析',
            href: 'https://mansion-market.com/contents/detail/15',
            img_src: require('../../../../images/top/magazine/ranking_15.png'),
          },
          {
            title: '豊島区のマンション売却相場は？いま売却して得する高評価マンションを独自分析',
            href: 'https://mansion-market.com/contents/detail/17',
            img_src: require('../../../../images/top/magazine/ranking_17.png'),
          },
          {
            title: '大田区のマンション売却相場は？いま売却して得する高評価マンションを独自分析',
            href: 'https://mansion-market.com/contents/detail/12',
            img_src: require('../../../../images/top/magazine/ranking_12.png'),
          },
        ],
      }
    },
  };
</script>

<style scoped lang="scss">
  @import 'app/assets/stylesheets/v2019/color.scss';

  .magazine_wrapper {

    section.magazine {
      width: 1024px;
      margin: 0 auto;
      padding: 0 0 110px 0;

      h2 {
        margin: 0 auto;
        padding: 0;
        box-sizing: border-box;
        letter-spacing: 1.3;
        font-size: 36px;
        color: $mm_black;
      }

      h3 {
        margin: 40px 0 25px 0;
        padding: 0;
        font-size: 28px;
        line-height: 1.8;
      }

      p.more {
        font-size: 18px;
        text-align: right;

        i.fa {
          margin: 0 10px 0 0;
        }
      }

      ul {
        display: flex;
        flex-flow: wrap;
        justify-content: space-between;
        margin: 25px 0;
        padding: 0;
        list-style: none;

        li {
          display: inline-block;
          margin: 0 0 20px 0;
          padding: 0;
          text-align: left;

          a {
            position: relative;
            display: block;
            width: 485px;
            height: 100px;
            overflow: hidden;
            font-size: 18px;
            text-decoration: none;
            color: $mm_black;

            &:hover:after,
            &:focus:after {
              position: absolute;
              content: '';
              display: block;
              z-index: 10;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              background: rgba($mm_white, 0.25);
            }

            img {
              margin: 0 0 6px 0;
              width: auto;
              height: 100px;
              vertical-align: middle;
              transform: scale(1);
              transition: .3s ease-in-out;
            }

            span {
              position: absolute;
              display: -webkit-box;
              z-index: 100;
              height: 100px;
              left: 200px;
              right: 0;
              bottom: 0;
              line-height: 1.8;
              text-overflow: ellipsis;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
            }

            &:hover span,
            &:focus span {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
</style>

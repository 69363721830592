<template lang="pug">
  section(data-mm-component-name="Mansions_Search")
    h2
      | マンションを探す

    Discover

    nav.recommended_in_metropolitan
      h3
        | 人気のエリア
      ul
        li(v-for="city in mansionsSearch.popularCities")
          a.lazyload(v-bind:href="city.link" v-bind:data-bg="city.image_url")
            | {{city.name}}
      p
        template(v-for="city in mansionsSearch.tokyoCities")
          a(v-bind:href="city.link")
            | {{city.name}}
          | ｜
        br
        template(v-for="city in mansionsSearch.kanagawaCities")
          a(v-bind:href="city.link")
            | {{city.name}}
          | ｜

    nav.recommended_stations
      h3
        | 人気の駅
      ul
        li(v-for="station in mansionsSearch.popularStations")
          a.lazyload(v-bind:href="station.link" v-bind:data-bg="station.image_url")
            | {{station.name}}

    nav.search_by_pref
      h3
        | 都道府県から探す
      ul
        li(v-for="prefecture in mansionsSearch.popularPrefectures")
          a.lazyload(v-bind:href="prefecture.link" v-bind:data-bg="prefecture.image_url")
            | {{prefecture.name}}
      dl(v-if="otherArea == 'open'")
        template(v-for="area in mansionsSearch.groupedPrefectures")
          dt
            | {{area.area_name}}
          dd
            template(v-for="prefecture in area.prefectures")
              a(v-bind:href="prefecture.link")
                | {{prefecture.name}}
              | ｜
      p.more(v-else)
        a(href="#mansions" v-on:click.stop.prevent="openOtherArea")
          | 他の都道府県も見る
          i.fa.fa-chevron-down(aria-hidden="true")

    Ranking

    nav.popular_mansions
      h3
        | おすすめマンション
      ul
        li(v-for="(mansion, index) in mansionsSearch.popularBuildings" v-bind:class="{'nonedisplay': hideExceededPopularBuilding(index) }")
          a.lazyload(v-bind:href="mansion.link" v-bind:data-bg="mansion.image_url")
            span
              | {{mansion.name}}
      p.more(v-if="morePopularBuildings != 'open'")
        a(href="#mansions" v-on:click.stop.prevent="openMorePopularBuildings")
          | もっと見る
          i.fa.fa-chevron-down(aria-hidden="true")

      p.banner
        a(href="/mansions/categories/brands")
          img.lazyload(v-bind:data-src="require('../../../../images/v2019/top/bnr_brand.jpg')" alt="ブランドマンション特集")
        a(href="/tower-mansion")
          img.lazyload(v-bind:data-src="require('../../../../images/v2019/top/bnr_tower.jpg')" alt="タワーマンション特集")
</template>

<script>
  import Discover from 'vue_apps/top/components/sp/Discover';
  import Ranking from 'vue_apps/top/components/sp/Ranking';

  export default {
    name: 'Mansions_Search',
    methods: {
      openOtherArea() {
        this.otherArea = 'open';
        return;
      },
      openMorePopularBuildings() {
        this.morePopularBuildings = 'open';
        return;
      },
      hideExceededPopularBuilding( index ) {
        // 「もっと見る」ボタンの制御
        if( this.morePopularBuildings == 'open' ) {
          // 押されたら全表示
          return false;
        } else if( index > 5 ){
          // 最初6件目以降は非表示
          return true;
        } else {
          return false;
        }
      }
    },
    data() {
      return {
        mansionsSearch: this.$store.state.mansionsSearch,
        otherArea: '',
        morePopularBuildings: '',
      }
    },
    components: {
      Discover,
      Ranking
    }
  };
</script>

<style scoped lang="scss">
  @import 'app/assets/stylesheets/v2019/color.scss';

  section {
    max-width: 400px;
    margin: 0 auto;
    padding: 175px 0 20px 0;

    a {
      color: $mm_exblue;
    }

    h2 {
      margin: 30px 0 20px 0;
      padding: 0 20px;
      box-sizing: border-box;
      font-size: 25px;
    }

    h3 {
      margin: 30px 0 0 0;
      padding: 0 20px;
      box-sizing: border-box;
      font-size: 16px;
    }

    p {
      margin: 15px 0;
      padding: 0 20px;
      box-sizing: border-box;
      font-size: 14px;
      line-height: 1.8;

      &.more {
        text-align: center;
        font-size: 18px;
        font-weight: bold;

        a {
          position: relative;
          display: block;
          padding: 0;
          height: 46px;
          line-height: 46px;
          text-decoration: none;
          border: solid 1px $mm_blue;
          border-radius: 5px;
          color: $mm_blue;
          background: $mm_white;

          &:link,
          &:visited {
            border-width: 2px;
          }

          &:hover,
          &:focus {
            background: $mm_blue;
            color: $mm_white;

            i.fa {
              color: $mm_white;
            }
          }

          i.fa {
            position: absolute;
            top: 50%;
            right: 11px;
            margin-top: -7px;
            font-size: 14px;
            color: $mm_blue;
          }
        }
      }
    }

    nav {

      &.recommended_in_metropolitan {
        margin-top: -10px;

        ul {
          display: flex;
          flex-flow: row wrap;
          justify-content: left;
          margin: 15px 10px 15px 20px;
          padding: 0;
          list-style: none;

          li {
            width: 50%;
            margin: 0;
            padding: 0 10px 0 0;
            box-sizing: border-box;
            text-align: center;

            a {
              display: block;
              margin: 0 0 10px 0;
              height: 85px;
              line-height: 85px;
              font-size: 20px;
              font-weight: bold;
              text-decoration: none;
              color: $mm_white;
              background: $mm_gray;
            }
          }
        }
      }

      &.recommended_stations {

        ul {
          display: flex;
          flex-flow: row wrap;
          justify-content: space-between;
          margin: 15px 20px;
          padding: 0;
          list-style: none;

          li {
            width: 31%;
            margin: 0 0 10px 0;
            padding: 0;
            text-align: center;

            a {
              display: block;
              width: 100%;
              height: 85px;
              line-height: 85px;
              font-size: 18px;
              font-weight: bold;
              text-decoration: none;
              color: $mm_white;
              background: $mm_gray;
            }
          }
        }
      }

      &.search_by_pref {

        ul {
          display: flex;
          flex-flow: row wrap;
          justify-content: left;
          margin: 15px 10px 10px 20px;
          padding: 0;
          list-style: none;

          li {
            width: 50%;
            margin: 0;
            padding: 0 10px 0 0;
            box-sizing: border-box;
            text-align: center;

            a {
              display: block;
              margin: 0 0 10px 0;
              height: 85px;
              line-height: 85px;
              font-size: 20px;
              font-weight: bold;
              text-decoration: none;
              color: $mm_white;
              background: $mm_gray;
            }
          }
        }

        dl {
          margin: 10px 20px 30px 20px;
          padding: 0;
          line-height: 1.8;

          dt {
            display: block;
            margin: 0;
            padding: 0;
            font-weight: bold;
          }

          dd {
            margin: 0;
            padding: 0;
          }
        }
      }

      &.popular_mansions {

        ul {
          display: flex;
          flex-flow: row wrap;
          justify-content: space-between;
          margin: 15px 20px;
          padding: 0;
          list-style: none;

          li {
            width: 31%;
            margin: 0 0 10px 0;
            padding: 0;
            text-align: left;

            &.nonedisplay {
              display: none;
            }

            a {
              position: relative;
              display: block;
              width: 100%;
              height: 145px;
              overflow: hidden;
              font-size: 14px;
              text-decoration: none;
              color: $mm_blue;
              background: $mm_white no-repeat top center;
              background-size: contain;

              span {
                position: absolute;
                display: block;
                width: 100%;
                height: 55px;
                left: 0;
                right: 0;
                bottom: 0;
                line-height: 1.4;
                background: $mm_white;
                border-top: solid 3px $mm_white;
              }
            }
          }
        }

        p.banner {
          margin: 40px 0;
          text-align: center;

          img {
            display: block;
            margin: 0 0 10px 0;
            width: 100%;
            vertical-align: middle;
          }
        }
      }
    }
  }
</style>

<template lang="pug">
  .media_wrapper(data-mm-component-name="Media")
    section.media
      h2
        | 掲載メディア紹介

      p.catch
        img.lazyload(v-bind:data-src="require('../../../../images/v2019/top/media_serif_sp.png')" alt="サービスのご紹介を頂きました")
      ul
        li(v-for="media in mediaNames")
          | {{media}}
        li.more
          a(href="https://corp.mansion-market.com/press/media/" target="_blank")
            | すべて見る
            i.fa.fa-angle-right(aria-hidden="true")

    section.mm_about_us
      p
        | 「マンションマーケット」は、マンションの購入＆売却をサポートする不動産ポータルサイトです。今まで不動産会社に問い合わせなければわからなかったマンションの相場情報を誰でも簡単に、しかも無料で調べることが可能です。マンションごとの相場価格（資産価値）や取引価格、所有しているマンションの価格をその場で調べることができる簡易査定機能など、購入＆売却する際に役立つ情報を調べることができます。また、不動産取引を担当する不動産エージェント（営業担当者）の実績や、マンションに対するレビュー（クチコミ）を見ることができ、購入＆売却について相談することができます。
</template>

<script>
  export default {
    name: 'Media',
    data() {
      return {
        mediaNames: [
          '日本経済新聞',
          '読売新聞',
          '日経ビジネス',
          '東洋経済',
          '日経Trendy',
          'IT Pro',
          '日経コンピュータ',
          '日経MJ',
          '日経マネー',
          'テレビ東京',
          '＠DIME',
        ],
      }
    },
  };
</script>

<style scoped lang="scss">
  @import 'app/assets/stylesheets/v2019/color.scss';

  .media_wrapper {
    padding: 1px 0;
    background: $mm_ultralightgray;

    section.media {
      max-width: 400px;
      margin: 40px auto 0 auto;
      padding: 0 0 50px 0;

      h2 {
        margin: 30px 0 20px 0;
        padding: 0 20px;
        box-sizing: border-box;
        font-size: 25px;
      }

      a {
        color: $mm_exblue;
      }

      ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 0;
        padding: 0 40px;
        list-style: none;
        color: $mm_darkgray;

        li {
          margin: 5px 0;
          padding: 0;
          width: 50%;
          font-size: 14px;

          &.more {

            i {
              margin: 0 0 0 10px;
            }
          }
        }
      }

      p {
        margin: 20px;
        padding: 0;
        line-height: 1.8;

        &.catch {
          margin: 0;
          text-align: center;

          img {
            width: auto;
            max-width: 320px;
            vertical-align: middle;
          }
        }
      }
    }

    section.mm_about_us {
      padding: 10px 0;
      background: $mm_white;

      p {
        margin: 20px auto;
        padding: 0 20px;
        max-width: 400px;
        box-sizing: border-box;
        font-size: 13px;
        color: $mm_darkgray;
        background: $mm_white;
      }
    }
  }
</style>

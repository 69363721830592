// GrandTop

import Vue from 'vue';
import Router from 'vue-router';
import App from 'vue_apps/top/App';
import store from 'vue_apps/top/store';

Vue.use(Router);

document.addEventListener('DOMContentLoaded', () => {
  const targetSelector = '[data-mm-mount-vue-app="GrandTopApp"]';
  const target = document.querySelector(targetSelector);
  const stateJson = target.getAttribute('data-mm-state');
  const state = JSON.parse(stateJson);
  store.commit('init', state);

  const router = new Router({
    mode: 'history',
  });

  new Vue({
    el: target,
    store,
    router,
    ...App,
  });
});


//

<template lang="pug">
  section.ranking(data-mm-component-name="Ranking")
    h3
      | 人気のマンションランキング
    .ranking_column
      h4
        | 閲覧数
      ol
        li(v-for="(rank, index) in pvRank")
          a(v-bind:href="rank.href")
            span.rank(v-bind:class="'rank_' + index")
              | {{index+1}}
            | {{rank.name}}

    .ranking_column
      h4
        | 人気
      ol
        li(v-for="(rank, index) in popularityRank")
          a(v-bind:href="rank.href")
            span.rank(v-bind:class="'rank_' + index")
              | {{index+1}}
            | {{rank.name}}

    .ranking_column
      h4
        | 価格上昇
      ol
        li(v-for="(rank, index) in priceIncreaseRank")
          a(v-bind:href="rank.href")
            span.rank(v-bind:class="'rank_' + index")
              | {{index+1}}
            | {{rank.name}}

    p.more
      a(href="/mansions/ranking")
        i.fa.fa-angle-right(aria-hidden="true")
        | ランキングをもっと見る
</template>

<script>
  export default {
    name: 'Ranking',
    data() {
      return {
        pvRank: [
          {
            href: 'https://mansion-market.com/mansions/detail/77077',
            name: 'スカイタワー41'
          },
          {
            href: 'https://mansion-market.com/mansions/detail/13375',
            name: '六本木ヒルズレジデンスB棟'
          },
          {
            href: 'https://mansion-market.com/mansions/detail/32404',
            name: 'ルフォン吉祥寺'
          },
          {
            href: 'https://mansion-market.com/mansions/detail/105421',
            name: 'シャンボール円山北町'
          },
          {
            href: 'https://mansion-market.com/mansions/detail/28002',
            name: '虎ノ門ヒルズレジデンス'
          }
        ],
        popularityRank: [
          {
            href: 'https://mansion-market.com/mansions/detail/41450',
            name: 'グリフィン新横浜アリーナ前'
          },
          {
            href: 'https://mansion-market.com/mansions/detail/78661',
            name: 'サーパス千手'
          },
          {
            href: 'https://mansion-market.com/mansions/detail/103245',
            name: 'グランプレステージ西明石駅前'
          },
          {
            href: 'https://mansion-market.com/mansions/detail/109225',
            name: 'プレシス杉並和田グランテラス'
          },
          {
            href: 'https://mansion-market.com/mansions/detail/111717',
            name: 'シティテラス越谷レイクタウン'
          }
        ],
        priceIncreaseRank: [
          {
            href: 'https://mansion-market.com/mansions/detail/450115',
            name: 'レフィール南麻布'
          },
          {
            href: 'https://mansion-market.com/mansions/detail/13375',
            name: '六本木ヒルズレジデンスB棟'
          },
          {
            href: 'https://mansion-market.com/mansions/detail/445769',
            name: 'ブランズ六本木ザレジデンス'
          },
          {
            href: 'https://mansion-market.com/mansions/detail/452558',
            name: 'マジェス元麻布ガーデンズ'
          },
          {
            href: 'https://mansion-market.com/mansions/detail/60368',
            name: 'フォレストテラス鳥居坂'
          }
        ]
      }
    }
  };
</script>

<style scoped lang="scss">
  @import 'app/assets/stylesheets/v2019/color.scss';

  section.ranking {
    margin: 0 20px;
    padding: 1px 0;

    h3 {
      margin: 30px 0 0 0;
      padding: 0;
      box-sizing: border-box;
      font-size: 16px;
    }

    a {
      color: $mm_black;
    }

    p.more {
      margin: 11px 0;
      font-size: 16px;
      text-align: right;

      i.fa {
        margin: 0 7px 0 0;
      }
    }

    .ranking_column {
      margin: 11px 0;
      padding: 11px 11px 7px 11px;
      box-sizing: border-box;
      background: $mm_bgblue;
      border-radius: 3px;

      h4 {
        margin: 0 auto;
        padding: 3px 0;
        width: 66%;
        box-sizing: border-box;
        text-align: center;
        font-size: 14px;
        letter-spacing: 2px;
        border-radius: 22px;
        background: $mm_blue;
        color: $mm_white;
      }

      ol {
        margin: 11px 0 0 0;
        padding: 0;
        list-style: none;

        li {
          margin: 7px 0;
          padding: 0;
          background: $mm_white;
          border-radius: 5px;
          overflow: hidden;

          a {
            position: relative;
            display: block;
            position: relative;
            margin: 0;
            padding: 15px 20px 15px 50px;
            box-sizing: border-box;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;

            &:after {
              position: absolute;
              display: block;
              top: 0;
              right: 0;
              width: 15px;
              height: 100%;
              line-height: 55px;
              text-align: center;
              text-decoration: none;
              content: '\f054'; // fa-chevron-right
              font-family: FontAwesome;
              font-size: 11px;
              color: $mm_white;
              background: $mm_blue;
            }

            span.rank {
              position: absolute;
              display: block;
              top: 0;
              left: 0;
              width: 50px;
              height: 100%;
              line-height: 55px;
              text-align: center;
              font-size: 11px;
              color: $mm_white;
              background: transparent url('../../../../images/top/ranking/icon4.png') no-repeat 50% 40%;
              background-size: 60%;

              &.rank_0 {
                background-image: url('../../../../images/top/ranking/icon1.png');
              }
              &.rank_1 {
                background-image: url('../../../../images/top/ranking/icon2.png');
              }
              &.rank_2 {
                background-image: url('../../../../images/top/ranking/icon3.png');
              }
            }
          }
        }
      }
    }
  }
</style>

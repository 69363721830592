<template lang="pug">
  .wrapper(data-mm-app-name="GrandTopApp")
    PortalTarget(name="grand-top-target")
    SpGrandTopPage(v-if="is_sp")
    PcGrandTopPage(v-else)
</template>

<script>
  import Vue from 'vue';
  import PortalVue, { PortalTarget } from 'portal-vue';
  import { mapState } from 'vuex';
  import SpGrandTopPage from 'vue_apps/top/layouts/sp/GrandTopPage';
  import PcGrandTopPage from 'vue_apps/top/layouts/pc/GrandTopPage';

  Vue.use(PortalVue);

  export default {
    name: 'GrandTopApp',
    computed: mapState(['is_sp']),
    components: {
      PortalTarget,
      SpGrandTopPage,
      PcGrandTopPage
    },
  };
</script>

<style scoped lang="scss">
</style>

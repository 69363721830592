import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const state = {
  is_sp: null,
  mansionsSearch: null,
  service: null,
  options: null,
  mainAreaPrefectures: null,
  mapPrefectures: null,
  show_area_modal: false,
  show_area_modal_prefecture: null,
};

const mutations = {
  init(state, initState) {
    state.is_sp = initState.is_sp;
    state.mansionsSearch = initState.mansionsSearch;
    state.service = initState.service;
    state.options = initState.options;
    state.mainAreaPrefectures = initState.mainAreaPrefectures;
    state.mapPrefectures = initState.mapPrefectures;
  },
  showAreaModal(state, prefecture) {
    state.show_area_modal = true;
    state.show_area_modal_prefecture = prefecture;
  },
  hideAreaModal(state) {
    state.show_area_modal = false;
    state.show_area_modal_prefecture = null;
  },
};

export default new Vuex.Store({
  state,
  mutations,
});

<template lang="pug">
  portal(v-bind:to="portalTo")
    .modal(data-mm-component-name="Modal" v-on:click.self="$emit('request-close')")
      aside
        .header
          p.title
            | {{title}}
          p.close
            button(v-on:click="$emit('request-close')" title="閉じる")
              span ×
        slot
</template>

<script>
  export default {
    name: 'Modal',
    props: {
      portalTo: String,
      title: String,
    },
  };
</script>

<style scoped lang="scss">
  @import 'app/assets/stylesheets/consumer/common/color.scss';

  .modal {
    position: fixed;
    z-index: 1000;
    padding: 1px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
    text-align: center;
    -webkit-overflow-scrolling: touch;
    background: rgba($mm_black, 0.55);

    &:after {
      display: inline-block;
      height: 100%;
      margin-left: -0.05em;
      vertical-align: middle;
      content: '';
    }

    aside {
      position: relative;
      display: inline-block;
      margin: 10px auto;
      padding: 0;
      width: 760px;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      vertical-align: middle;
      color: $mm_black;
      background: $mm_white;
      box-sizing: border-box;
      box-shadow: 0 3px 18px $mm_darkgray;

      @media screen and (max-width: 760px) {
        & {
          width: 95%;
        }
      }

      .header {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        margin: 0;
        padding: 0;
        background: $mm_blue;
        color: $mm_white;

        .title {
          flex: auto;
          margin: 0;
          padding: 0 0 0 22px;
          min-height: 50px;
          line-height: 50px;
          font-size: 16px;
          font-weight: bolder;
          text-align: left;

          @media screen and (max-width: 320px) {
            & {
              letter-spacing: -1px;
            }
          }
        }

        .close {
          width: 50px;
          margin: 0;
          padding: 0;

          button {
            width: 50px;
            height: 50px;
            line-height: 44px;
            margin: 0;
            padding: 0;
            overflow: hidden;
            box-sizing: border-box;
            border: none;
            border-radius: 0;
            background: $mm_blue;
            color: $mm_white;

            &:before {
              content: '×';
              font-size: 30px;
            }

            &:focus,
            &:hover {
              color: $mm_white;
              cursor: pointer;
            }

            span {
              display: none;
            }
          }
        }
      }
    }
  }
</style>

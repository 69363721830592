<template lang="pug">
  .services_wrapper(data-mm-component-name="Services")

    section
      h2
        | サービス紹介

      nav.services_menu.satei
        h3
          | マンションを売る
        ul
          li(v-for="satei in service.sateiServices")
            a(v-bind:href="satei.link")
              | {{satei.name}}
              i.fa.fa-chevron-right(aria-hidden="true")

      nav.services_menu.purchase
        h3
          | マンションを買う
        ul
          li(v-for="purchase in service.purchaseServices")
            a(v-bind:href="purchase.link")
              | {{purchase.name}}
              i.fa.fa-chevron-right(aria-hidden="true")

      nav.services_menu.how_to_mansion
        h3
          | マンションについて知る
        ul
          li(v-for="howTo in service.howToMansion")
            a(v-bind:href="howTo.link")
              | {{howTo.name}}
              i.fa.fa-chevron-right(aria-hidden="true")

      h3
        | マンションを探す
      h4
        | 東京都
      p
        template(v-for="city in service.searchArea.tokyo")
          a(v-bind:href="city.link")
            | {{city.name}}
          | ｜
      h4
        | 神奈川県
      p
        template(v-for="city in service.searchArea.kanagawa")
          a(v-bind:href="city.link")
            | {{city.name}}
          | ｜
      h4
        | 千葉県
      p
        template(v-for="city in service.searchArea.chiba")
          a(v-bind:href="city.link")
            | {{city.name}}
          | ｜
      h4
        | 埼玉県
      p
        template(v-for="city in service.searchArea.saitama")
          a(v-bind:href="city.link")
            | {{city.name}}
          | ｜
</template>

<script>
  export default {
    name: 'Services',
    data() {
      return {
        service: this.$store.state.service,
      }
    },
  };
</script>

<style scoped lang="scss">
  @import 'app/assets/stylesheets/v2019/color.scss';

  .services_wrapper {
    padding: 1px 0 40px 0;
    background: $mm_white;

    section {
      max-width: 400px;
      margin: 0 auto;

      h2 {
        margin: 50px auto 20px auto;
        padding: 0 20px;
        box-sizing: border-box;
        font-size: 25px;
      }

      h3 {
        margin: 30px 0 0 0;
        padding: 0 20px;
        box-sizing: border-box;
        font-size: 16px;
      }

      h4 {
        margin: 15px 0 0 0;
        padding: 0 20px;
        font-size: 14px;
      }

      p {
        margin: 5px 20px;
        padding: 0;
        line-height: 1.8;
      }

      a {
        color: $mm_exblue;
      }

      .services_menu {
        margin: 0 20px 20px 20px;

        &.purchase ul li a:after {
          background: $mm_bggreen;
        }

        &.how_to_mansion ul li a:after {
          background: $mm_bgorange;
        }

        h3 {
          padding: 0;
        }

        ul {
          margin: 15px 0;
          padding: 0;
          list-style: none;
          overflow: hidden;
          border: solid 1px $mm_disablegray;
          border-radius: 7px;

          li {
            margin: 0;
            padding: 0;

            &:last-child a {
              border-bottom: none;
            }

            a {
              position: relative;
              display: block;
              padding: 0 0 0 15px;
              height: 45px;
              line-height: 45px;
              box-sizing: border-box;
              font-size: 14px;
              text-decoration: none;
              border-bottom: solid 1px $mm_disablegray;

              &:hover,
              &:focus {
                background: $mm_ultralightgray;
              }

              &:after {
                position: absolute;
                z-index: 100;
                content: '';
                top: 0;
                bottom: 0;
                right: 0;
                width: 30px;
                height: 100%;
                background: $mm_whiteblue;
              }

              i {
                position: absolute;
                z-index: 1000;
                top: 50%;
                right: 9px;
                margin: -6px 0 0 0;
                font-weight: normal;
                color: $mm_gray;
              }
            }
          }
        }
      }
    }
  }
</style>

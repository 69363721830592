<template lang="pug">
  .modal_wrapper(data-mm-component-name="AreaModal")
    Modal(portal-to="grand-top-target" v-bind:title="prefecture.name+'の沿線・駅'" v-on:request-close="hideAreaModal")
      .content
        template(v-for="company in prefecture.railway_companies")
          h4
            | {{company.name}}
          ul
            li(v-for="railway in company.railways")
              a(v-bind:href="railway.path")
                | {{railway.name}}
                i.fa.fa-chevron-right(aria-hidden="true")
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import Modal from 'lib/components/Modal';

  export default {
    name: 'AreaModal',
    data() {
      return {
        prefecture: this.$store.state.show_area_modal_prefecture,
      };
    },
    methods: {
      ...mapMutations(['hideAreaModal']),
    },
    components: {
      Modal,
    },
  };
</script>

<style scoped lang="scss">
  @import 'app/assets/stylesheets/v2019/color.scss';

  .content {
    text-align: left;

    h4 {
      margin: 0 1px;
      padding: 7px 11px;
      font-size: 16px;
      font-weight: bold;
      line-height: 1.6;
      background: $mm_ultralightgray;
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        margin: 0;
        padding: 0;

        a {
          position: relative;
          display: block;
          padding: 15px 22px;
          font-size: 16px;
          border-bottom: solid 1px $mm_ultralightgray;

          &:hover,
          &:focus {
            background: $mm_whiteblue;
          }

          i.fa {
            position: absolute;
            top: 50%;
            right: 22px;
            margin-top: -8px;
            font-size: 18px;
            content: '\f105'; // fa-angle-right
          }
        }
      }
    }
  }
</style>
